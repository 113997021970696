var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline-group" },
    _vm._l(_vm.taskHistory, function (value, key, index) {
      return _c(
        "div",
        { key: index, staticClass: "timeline" },
        [
          _c(
            "span",
            { staticClass: "grey-darken-4--text font-weight-bold mb-5" },
            [_vm._v(_vm._s(_vm.formatDateForHeading(key)))]
          ),
          _vm._l(value, function (item, i) {
            return _c("div", { key: i, staticClass: "timeline-item" }, [
              _c("div", { staticClass: "timeline-info timeline-text" }, [
                _c("div", { staticClass: "timeline-date" }, [
                  _vm._v(
                    " " + _vm._s(_vm.formatDateToTime(item.created_at)) + " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "timeline-divider" }, [
                _c("div", {
                  staticClass: "timeline-divider__before ml-1",
                  class: i === 0 ? "timeline-divider-first" : "",
                }),
                _c("div", { staticClass: "timeline-divider__dot" }),
                _c("div", {
                  staticClass: "timeline-divider__after ml-1",
                  class: i === value.length - 1 ? "timeline-divider-last" : "",
                }),
              ]),
              _c(
                "div",
                { staticClass: "timeline-content timeline-text ml-2" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _vm._v(" " + _vm._s(item.historyText) + " "),
                  _c(
                    "span",
                    { staticClass: "light-blue--text text--darken-2" },
                    [
                      _vm._v(
                        _vm._s(
                          item.changedItem ? '"' + item.changedItem + '"' : ""
                        )
                      ),
                    ]
                  ),
                ]
              ),
            ])
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }