<template>
  <div class="timeline-group">
    <div
      class="timeline"
      v-for="(value, key, index) in taskHistory"
      :key="index"
    >
      <span class="grey-darken-4--text font-weight-bold mb-5">{{
        formatDateForHeading(key)
      }}</span>
      <div v-for="(item, i) in value" :key="i" class="timeline-item">
        <div class="timeline-info timeline-text">
          <div class="timeline-date">
            {{ formatDateToTime(item.created_at) }}
          </div>
        </div>
        <div class="timeline-divider">
          <div
            class="timeline-divider__before ml-1"
            :class="i === 0 ? 'timeline-divider-first' : ''"
          ></div>
          <div class="timeline-divider__dot"></div>
          <div
            class="timeline-divider__after ml-1"
            :class="i === value.length - 1 ? 'timeline-divider-last' : ''"
          ></div>
        </div>
        <div class="timeline-content timeline-text ml-2">
          <span class="font-weight-bold">{{ item.name }}</span>
          {{ item.historyText }}
          <span class="light-blue--text text--darken-2">{{
            item.changedItem ? `"${item.changedItem}"` : ""
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const additionalKeys = [
  "assignee_ids",
  "task_name",
  "associated_ticket_ids",
  "priority",
  "step_id",
  "progress",
  "start_date",
  "end_date",
  "is_complete",
  "todo_items",
];
export default {
  name: "TaskHistory",
  components: {},
  mixins: [],
  props: {
    taskId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {
    this.getTaskHistory();
  },
  mounted() {
    this.adjustHeightOfDivider();
  },
  updated() {
    this.adjustHeightOfDivider();
  },
  computed: {
    taskHistory() {
      const history = this.$store.state.TaskHistory.history[this.taskId];
      if (history && history.length) {
        history.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        history[history.length - 1].isCreated = true;
        // group items based on date
        const groupedData = history.reduce((result, obj, index) => {
          const createdDate = new Date(obj.created_at).toLocaleDateString(
            "en-US",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            },
          );

          // Check if the object contains non-null and non-undefined values for specific properties
          if (index !== history.length - 1) {
            const hasAdditionalValues = additionalKeys.some(
              (key) => obj[key] !== null && obj[key] !== undefined,
            );
            if (!result[createdDate]) {
              result[createdDate] = [];
            }
            if (hasAdditionalValues) {
              // Add additional properties with non-null and non-undefined values to the new object
              additionalKeys.forEach((key) => {
                if (obj[key] !== null && obj[key] !== undefined) {
                  let newObj = {
                    created_at: obj.created_at,
                    created_by: obj.created_by,
                  };
                  newObj[key] = obj[key];
                  result[createdDate].push(
                    Object.assign(
                      {},
                      newObj,
                      this.getHistoryDetailsForDisply(newObj),
                    ),
                  );
                }
              });
            }
          } else {
            if (!result[createdDate]) {
              result[createdDate] = [];
            }
            const userName = this.getUserName(obj.created_by);
            let tempObj = {
              name: userName,
              historyText: "created",
              changedItem: obj["task_name"],
              created_at: obj.created_at,
              created_by: obj.created_by,
            };
            result[createdDate].push(tempObj);
          }
          return result;
        }, {});
        return groupedData;
      }
      return {};
    },
  },
  methods: {
    formatDateToTime(date) {
      const dateTime = moment(date).format("hh:mm a");
      return dateTime;
    },
    formatDateForHeading(date) {
      return moment(date).format("DD MMMM YYYY, dddd");
    },
    getHistoryDetailsForDisply(historyItem) {
      const userName = this.getUserName(historyItem.created_by);
      if (historyItem.task_name) {
        return {
          name: userName,
          historyText: "changed task name to",
          changedItem: historyItem.task_name,
        };
      }
      if (historyItem.start_date) {
        return {
          name: userName,
          historyText: "changed start date to ",
          changedItem: moment(historyItem.start_date).format("MM/DD/YYYY"),
        };
      }
      if (historyItem.end_date) {
        return {
          name: userName,
          historyText: "changed end date to",
          changedItem: moment(historyItem.end_date).format("MM/DD/YYYY"),
        };
      }
      if (historyItem.assignee_ids) {
        return {
          name: userName,
          historyText: "changed assignees to",
          changedItem: this.getAssigneesNames(historyItem.assignee_ids),
        };
      }
      if (historyItem.associated_ticket_ids) {
        return {
          name: userName,
          historyText: "changed associted tickets to",
          changedItem: this.getTicketNumbers(historyItem.associated_ticket_ids),
        };
      }
      if (historyItem.priority) {
        return {
          name: userName,
          historyText: "changed priority",
        };
      }
      if (historyItem.step_id) {
        return {
          name: userName,
          historyText: "changed status",
        };
      }
      if (historyItem.progress) {
        return {
          name: userName,
          historyText: "changed progress",
        };
      }
      if (historyItem.todo_items) {
        return {
          name: userName,
          historyText: "changed to do items",
        };
      }
      if (historyItem.is_complete) {
        return {
          name: userName,
          historyText: "changed completed status",
        };
      }
    },
    getUserName(userId) {
      const companyUsers = this.$store.state.User.companyUsers;
      const user = companyUsers.find((user) => {
        return user.user_id === userId;
      });
      return user?.name;
    },
    getAssigneesNames(assignee_ids) {
      const users = this.$store.state.User.companyUsers;
      const names = [];

      assignee_ids.forEach((userId) => {
        const user = users.find((user) => user.user_id === userId);
        if (user) {
          names.push(user.name);
        }
      });
      return names.join(", ");
    },
    getTicketNumbers(ticket_ids) {
      const tickets = Object.values(this.$store.state.Ticket.tickets);
      const ticketNumbers = [];

      ticket_ids.forEach((ticketId) => {
        const ticket = tickets.find((ticket) => ticket.ticket_id === ticketId);
        if (ticket) {
          ticketNumbers.push(`${ticket.center_ticket_id}-${ticket.revision}`);
        }
      });
      return ticketNumbers.join(", ");
    },
    async getTaskHistory() {
      const historyResponse = await this.$store.dispatch("getHistoryByTaskId", {
        task_id: this.taskId,
      });
      if (historyResponse.status !== "error") {
        const historyObject = {};
        historyObject[this.taskId] = historyResponse;
        this.$store.commit("setTaskHistoryData", historyObject);
      }
    },
    adjustHeightOfDivider() {
      const dividerAfterElements = document.querySelectorAll(
        ".timeline-divider__after",
      );
      dividerAfterElements.forEach((element) => {
        element.style.height = `${
          element.parentElement.parentElement.offsetHeight - 26
        }px`;
      });
    },
  },
};
</script>

<style lang="scss">
.timeline {
  display: flex;
  flex-direction: column;
}

.timeline-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.timeline-content {
  margin-right: 20px;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 4px;
}
.timeline-text {
  margin-bottom: 15px;
}

.timeline-info {
  padding: 10px;
}

.timeline-date {
  flex: 0 0 auto;
  min-width: 59px;
}
.timeline-divider__before {
  height: 15px;
  background: #e0e0e0;
  width: 1px;
}
.timeline-divider__after {
  background: #e0e0e0;
  width: 1px;
}
.timeline-divider__dot {
  height: 11px;
  width: 11px;
  background-color: #757575;
  border-radius: 50%;
}
.timeline-divider-first,
.timeline-divider-last {
  background-color: transparent;
}
</style>
